<template>
  <main class="main">
    <Search @ready-state="picFilms" @select-changed="picFilms" />
    <FilmList :films="films" @select-film="takeId" />
  </main>
</template>

<script>
import { picFilms } from "./mixins/fetch";
export default {
  mixins: [picFilms],  
  data() {
    return {
      id: null,
      films: [],
      picked: null,
    };
  },
  components: {
    Search: () => import("./Search"),
    FilmList: () => import("./FilmList"),
  },
  mounted() {},
  methods: {
    takeId(index) {
      let choosenFilm = this.films[index].id;
      this.id = choosenFilm;      
      this.$emit("show-choosen", {
        id: this.id, 
        picked: this.picked}
        );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.main {
  * {
    font-size: 16px;
  }
}
</style>
