export const picFilms = {
    methods: {
        picFilms(param) {
            setTimeout(() => {
                this.films = [];
                let url;
                this.picked = param.picked;
                if (param.picked == "movie") {
                    url = `http://api.themoviedb.org/3/discover/${param.picked}?api_key=8089a6eb2db7e1328d6cacafa1b57a70&sort_by=popularity.desc&include_adult=false&include_video=false&year=${param.currentYear}`
                } else {
                    url = `http://api.themoviedb.org/3/discover/${param.picked}?api_key=8089a6eb2db7e1328d6cacafa1b57a70&sort_by=popularity.desc&first_air_date_year=${param.currentYear}&include_null_first_air_dates=false`
                }
                fetch(url)
                    .then((response) => response.json())
                    .then((answer) => {
                        //перебираем полученные с сервера данные
                        for (let i in answer.results) {
                            //сортируем полученный массив на основании популярности
                            const sorted = answer.results.sort((prev, curr) => prev.popularity - curr.popularity);
                            //пушим полученный массив в массив вывода фильмов для отображения на стрнанице
                            this.films.push(sorted[i])
                        }
                        //выбираем только 10 фильмов, раньше выбрать нельзя, т.к. не во всех годах есть достаточное количество фильмов
                        this.films = this.films.splice(0, 10)
                    });

            })
        }
    },
}


export const showFilmDetails = {
    methods: {
        showFilmDetails() {
            setTimeout(() => {
                let url;
                
                    url = `https://api.themoviedb.org/3/${this.picked}/${this.id}?api_key=8089a6eb2db7e1328d6cacafa1b57a70&language=en-US`
                
                fetch(url)
                    .then((response) => response.json())
                    .then((answer) => {
                        this.filmData.push(answer)
                    })
                
                console.log('this.filmData: ', this.filmData);
            })
        }
    },
}